let BASE_URI: string | null = 'http://localhost:5000'
let API_BASE_URI: string | null = 'http://localhost:4000'
let STRIPE_API_KEY: string | null = 'pk_test_nYVHsex9YtLnndKBp6HmxyAN'
let GA_TRACKING_ID: string | null = 'G-P14ZQ0J3YP'

const PRODUCTION = process.env.NODE_ENV === 'production'

if (PRODUCTION) {
  BASE_URI = 'https://potico.fr'
  API_BASE_URI = process.env.NEXT_PUBLIC_POTICO_API_URI ?? null
  STRIPE_API_KEY = process.env.NEXT_PUBLIC_STRIPE_API_KEY ?? null
}

export { BASE_URI, API_BASE_URI, STRIPE_API_KEY, GA_TRACKING_ID }
